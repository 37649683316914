<template>
  <div class="app-container">
    <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
      <el-tab-pane v-if="checkPermission(['ADMIN','WITHDRAW_ALL','WITHDRAW_SELECT'])" label="全部列表" name="8">
        <tableView :refresh="refresh8" :tableStatus='-1'/>
      </el-tab-pane>
      <el-tab-pane v-if="checkPermission(['ADMIN','WITHDRAW_ALL','WITHDRAW_CUS_CHECK'])" label="客服部审核" name="1">
        <tableView :refresh="refresh1" :tableStatus='1'/>

      </el-tab-pane>
      <el-tab-pane v-if="checkPermission(['ADMIN','WITHDRAW_ALL','WITHDRAW_SEL_F_CHECK'])" label="清算部审核" name="2">
        <tableView :refresh="refresh2" :tableStatus='2'/>
      </el-tab-pane>
      <el-tab-pane v-if="checkPermission(['ADMIN','WITHDRAW_ALL','WITHDRAW_TRADE_CHECK'])" label="交易部审核" name="3">
        <tableView :refresh="refresh3" :tableStatus='3'/>
      </el-tab-pane>
      <el-tab-pane v-if="checkPermission(['ADMIN','WITHDRAW_ALL','WITHDRAW_SEL_S_CHECK'])" label="清算部复核" name="4">
        <tableView :refresh="refresh4" :tableStatus='4'/>
      </el-tab-pane>
      <el-tab-pane v-if="checkPermission(['ADMIN','WITHDRAW_ALL','WITHDRAW_FIN_CHECK'])" label="财务部出金" name="5">
        <tableView :refresh="refresh5" :tableStatus='5'/>
      </el-tab-pane>
      <el-tab-pane v-if="checkPermission(['ADMIN','WITHDRAW_ALL','WITHDRAW_SELECT'])" label="已出金" name="6">
        <tableView :refresh="refresh6" :tableStatus='6'/>
      </el-tab-pane>
      <el-tab-pane v-if="checkPermission(['ADMIN','WITHDRAW_ALL','WITHDRAW_SELECT'])" label="已否决/取消" name="7">
       <tableView :refresh="refresh7" :tableStatus='99'/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>

import tableView from '@/components/finance/withdraw/table'
import checkPermission from '@/utils/permission'
export default {
  name:'withdrawchk',
  components: { tableView },
  data(){
    return {
      activeName:'8',refresh1:false,refresh2:false,refresh3:false,refresh4:false,
      refresh5:false,refresh6:false,refresh7:false,refresh8:false
    }
  },
  methods:{
    checkPermission,
    handleClick(tab, event) {
        switch(tab.name){
          case '1':
            this.refresh1=!this.refresh1
          break;
          case '2':
            this.refresh2=!this.refresh2
          break;
          case '3':
            this.refresh3=!this.refresh3
          break;
          case '4':
            this.refresh4=!this.refresh4
          break;
          case '5':
            this.refresh5=!this.refresh5
          break;
          case '6':
            this.refresh6=!this.refresh6
          break;
          case '7':
            this.refresh7=!this.refresh7
          break;
          case '8':
            this.refresh8=!this.refresh8
          break;
        }
    }
  }
}
</script>

<style scoped>

</style>
